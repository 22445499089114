import React from "react";
import DamDrawer from "../DamDrawer";
import UploadDrawerContent from "./UploadDrawerContent";

export default function DrawerUpload(props) {

	return (
		<DamDrawer
			open={true}
			title={translate("Upload Files")}
			onClose={props.onClose}
		>
			<UploadDrawerContent />
		</DamDrawer>
	);
}