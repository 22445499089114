import React, { useState, useEffect, useRef, useContext } from "react";
import Cookies from 'js-cookie';
import SystemContext, { UpdateSystemContext } from "./SystemContext";
import DisplayBridgeHelper from "./utils/helpers/DisplayBridgeHelper";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import _, { debounce } from "lodash";

import { LeftNav } from "@simpleview/sv-mosaic";

import DownloadQueueIcon from '@material-ui/icons/BurstMode';
import ImageIcon from '@material-ui/icons/Image';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import UploadAssetsIcon from './customIcons/Upload';
import ReviewIcon from './customIcons/FileEye';
import CollectionsIcon from './customIcons/FileMultiple';

const StyledDiv = styled.div`
	& > div {
		display: flex;
		height: calc(100vh - 64px);
		left: 0px;
		position: fixed;
		position: static;
		top: 64px;
		z-index: 20000;
	}

	& a:hover, a:focus {
		text-decoration: none!important;
	}

	& > .nav-bridge {
		display: none;
	}
`;

// If the innerWidth of our screen is less than 1024 we utilize mobile
function isMobile() {
	return window.innerWidth < 1024;
}

// iOS11-12 has a bug where events don't bubble up unless there is a listener of that type in the parent chain before the body.
// This ensures that the outer wrapper has a click listener, allowing the LeftNav to properly close on a click away.
// https://stackoverflow.com/a/39712411/435223
const noop = function () { };

const navMap = [
	{
		interceptTarget : 'all-assets-nav-item',
		path : '/'
	},
	{
		interceptTarget : 'adminAccessGroupsReact',
		path : '/admin/accessGroups'
	},
	{
		interceptTarget : 'admin-users-nav-item',
		path : '/admin/adminUsers'
	},
	{
		interceptTarget : 'adminCollectionReviewReact',
		path : '/admin/collections'
	},
	{
		interceptTarget : 'users-nav-item',
		path : '/admin/users'
	},
	{
		interceptTarget : 'admin-users-files-item',
		path : '/admin/files'
	},
	{
		interceptTarget : 'admin-user-uploads',
		path : '/admin/users/uploads'
	},
	{
		interceptTarget : 'adminNotificationsReact',
		path : '/admin/notifications/newUser'
	},
	{
		interceptTarget : 'requestAuthorizationReact',
		path : '/admin/users/downloadRequests'
	},
	{
		interceptTarget : 'categoriesReact',
		path : '/admin/tags/asset'
	},
	{
		interceptTarget : 'user-uploads-nav-item',
		path : '/user/uploads'
	}
]

export default function DamNav(props) {

	const context = React.useContext(SystemContext);

	let urlArray = [];
	let urlQueryString = window.location.search.toString().replace(/^[?]{1}/,"");
	urlArray = urlQueryString.split("&");
	if (urlArray.length) {
		if (urlArray.includes('myUploadPreview=true') === true && !(Cookies.get('myUploadPreview') !== undefined && Cookies.get('myUploadPreview') === "true")) {
			Cookies.set('myUploadPreview', true, { path: '/' });
		}
		else if (urlArray.includes('myUploadPreview=false') === true && Cookies.get('myUploadPreview') !== undefined && Cookies.get('myUploadPreview') === "true") {
			Cookies.remove('myUploadPreview');
		}
		if (urlArray.includes('categoriesPreview=true') === true && !(Cookies.get('categoriesPreview') !== undefined && Cookies.get('categoriesPreview') === "true")) {
			Cookies.set('categoriesPreview', true, { path: '/' });
		}
		else if (urlArray.includes('categoriesPreview=false') === true && Cookies.get('categoriesPreview') !== undefined && Cookies.get('categoriesPreview') === "true") {
			Cookies.remove('categoriesPreview');
		}
	}

	if (Cookies.get('navigationVariant') !== undefined && Cookies.get('platformSwitch') !== undefined && Cookies.get('platformSwitch') === "1") {
		localStorage.setItem("sv-mosaic-left-nav-variant", Cookies.get('navigationVariant'));
		Cookies.remove('platformSwitch');
	}
	else {
		let navigationVariant = localStorage.getItem("sv-mosaic-left-nav-variant") !== null ? localStorage.getItem("sv-mosaic-left-nav-variant") : "full";
		Cookies.set('navigationVariant', navigationVariant, { path: '/' });
	}

	const collectionsForCurrentUser = (_.get(context, 'collections') && context.collections.length > 0) ? context.collections.map(c => {return {
		name: `collections.${c.id}`,
		label: c.title,
		ui: 'legacy',
		call: ()  => {
			window.bs.getColl(c.id, false, true);
		}
	} }) : [];

	const links = navMap.map( (navitem, i) => {
		const bridgeNavId = navitem.interceptTarget + '-bridge-nav-item';
		return <li key={i}><Link to={navitem.path} id={bridgeNavId}>{bridgeNavId}</Link></li>
	});

	const downloadRequestItem = {
		name: "settings.downloadRequests.reviewRequests",
		label: "Review Requests",
		call: () => {
			document.getElementById('requestAuthorizationReact-bridge-nav-item').click();
			resetViewContainersForReact();
		},
		resetReviewRequests: true
	};

	const downloadQueueItem = {
		name: "download_queue",
		label: "Download Queue",
		ui: 'legacy',
		call: () => {
			loadExistingQueue();
			document.getElementById('queueList').style.width = '100%';
		},
		resetDownloadQueue: true,
		mIcon: DownloadQueueIcon
	};

	const reviewFilesItem = {
		name: "review.files",
		label: "Files",
		call: () => {
			document.getElementById('admin-users-files-item-bridge-nav-item').click();
			resetViewContainersForReact();
		}
	};

	let uploadAssetsNavItem = {};
	if (_.get(context, "legacy.user.user_type") && context.legacy.user.user_type === "user_admin") {
		uploadAssetsNavItem = {
			name: "uploadAssets",
			label: "Upload Assets",
			ui: "legacy",
			targetId: "assetUpload",
			mIcon: UploadAssetsIcon,
			call: () => {
				handleAssetUploadClick();
			},
			show: () => {
				return (_.get(context, "legacy.user.user_type") && context.legacy.user.user_type === "user_admin");
			}
		};
	}
	else {
		uploadAssetsNavItem = {
			name: "uploadAssets",
			label: "Upload Assets",
			ui: "legacy",
			targetId: "uploadTermsAndConditions",
			mIcon: UploadAssetsIcon,
			call: () => {
				handleAssetUploadClick();
			},
			show: () => {
				return _.get(context, "legacy.user.has_contributor_group", false);
			}
		};
	}
	if (Cookies.get('myUploadPreview') !== undefined && Cookies.get('myUploadPreview') === "true") {
		uploadAssetsNavItem = {
			name: "uploadAssets",
			label: "Upload Assets",
			ui: 'legacy',
			targetId: 'assetUpload',
			mIcon: UploadAssetsIcon,
			call: () => {
				document.getElementById("user-uploads-nav-item-bridge-nav-item").click();
				resetViewContainersForReact();
			},
			show: () => {
				return ((_.get(context, "legacy.user.user_type") && context.legacy.user.user_type === "user_admin") || (_.get(context, "legacy.user.has_contributor_group", false)));
			}
		};
	}

	let categoriesNavItem = {
		name: "review.categories",
		label: "Categories",
		items: [
			{
				name: "review.categories.image",
				label: "Images",
				ui: "modal",
				call: () => {
					bs.folders.manage("image");
				}
			},
			{
				name: "review.categories.video",
				label: "Video",
				ui: "modal",
				call: () => {
					bs.folders.manage("video");
				}
			},
			{
				name: "review.categories.audio",
				label: "Audio",
				ui: 'modal',
				call: () => {
					bs.folders.manage("audio");
				}
			},
			{
				name: "review.categories.other",
				label: "Documents & Logos",
				ui: 'modal',
				call: () => {
					bs.folders.manage("other");
				}
			}
		]
	}
	if (Cookies.get('categoriesPreview') !== undefined && Cookies.get('categoriesPreview') === "true") {
		categoriesNavItem = {
			name: "review.categories",
			label: "Categories",
			call: () => {
				document.getElementById("categoriesReact-bridge-nav-item").click();
				resetViewContainersForReact();
			}
		}
	}

	let reviewNavigationArray = [
		{
			name: "review.adminUsers",
			label: "Admin Users",
			call: () => {
				document.getElementById('admin-users-nav-item-bridge-nav-item').click();
				resetViewContainersForReact();
			}
		},
		{
			name: "review.users",
			label: "Users",
			call: () => {
				document.getElementById('users-nav-item-bridge-nav-item').click();
				resetViewContainersForReact();
			}
		},
		{
			name: "review.notifications",
			label: "Notifications",
			call: () => {
				document.getElementById('adminNotificationsReact-bridge-nav-item').click();
				resetViewContainersForReact();
			},
			show: () => {
				return !(_.get(context, 'legacy.user.disable_admin_controls') && context.legacy.user.disable_admin_controls === 1);
			}
		},
		{
			name: "review.accessGroups",
			label: "Access Groups",
			call: () => {
				document.getElementById('adminAccessGroupsReact-bridge-nav-item').click();
				resetViewContainersForReact();
			},
			show: () => {
				return !(_.get(context, 'legacy.user.disable_admin_controls') && context.legacy.user.disable_admin_controls === 1);
			}
		},
		reviewFilesItem,
		categoriesNavItem,
		{
			name: "review.collections",
			label: "Collections",
			call: () => {
				document.getElementById('adminCollectionReviewReact-bridge-nav-item').click();
				resetViewContainersForReact();
			}
		},
		{
			name: "review.statistics",
			label: "Statistics",
			ui: 'legacy',
			targetId: 'adminReports',
			targetURL: '/ajax/admin5/report_list_statistics.php',
			show: () => {
				return !(_.get(context, 'legacy.user.disable_admin_controls') && context.legacy.user.disable_admin_controls === 1);
			}
		}
	];

	if (currentUser !== undefined && currentUser.disable_admin_controls !== undefined && currentUser.disable_admin_controls === 1 && currentUser.user_type !== undefined && currentUser.user_type === "user_admin") {
		reviewNavigationArray = [
			reviewFilesItem,
			categoriesNavItem,
			{
				name: "review.collections",
				label: "Collections",
				call: () => {
					document.getElementById('adminCollectionReviewReact-bridge-nav-item').click();
					resetViewContainersForReact();
				}
			}
		];
	}

	const dataSets = {
		dam_flat: [
			{
				name: "assets",
				label: "Assets",
				mIcon: ImageIcon,
				call: () => {
					document.getElementById('all-assets-nav-item-bridge-nav-item').click();
					resetViewContainersForReact();
				}
			},
			downloadQueueItem,
			uploadAssetsNavItem,
			{
				name: "collections",
				label: "Collections",
				ui: 'legacy',
				targetId: 'assetUpload',
				mIcon: CollectionsIcon,
				show: () => {
					return (_.get(context, 'collections') && context.collections.length > 0);
				},
				items: collectionsForCurrentUser
			}
		]
	};


	if (!(currentUser !== undefined && currentUser.disable_admin_controls !== undefined && currentUser.disable_admin_controls === 1 && currentUser.user_type !== undefined && currentUser.user_type === "user_admin")) {
		dataSets.dam_flat.push({
			name: "settings",
			label: "Settings",
			mIcon: SettingsIcon,
			items: [
				{
					name: "settings.addUser",
					label: "Add User",
					ui: 'legacy',
					targetId: 'adminReports',
					targetURL: '/administration5/edit_user_plain.php?mode=add'
				},
				{
					name: "settings.userUploads",
					label: "User Uploads",
					call: () => {
						document.getElementById('admin-user-uploads-bridge-nav-item').click();
						resetViewContainersForReact();
					}
				},
				{
					name: "settings.downloadRequests",
					label: "Download Requests",
					items: [
						downloadRequestItem,
						{
							name: "settings.downloadRequests.agreementSettings",
							label: "Agreement Settings",
							ui: 'legacy',
							targetId: 'adminReports',
							targetURL: '/ajax/admin5/edit_request_settings.php'
						},
					]
				},
			],
			show: () => {
				return (_.get(context, 'legacy.user.user_type', false) === 'user_admin');
			}
		});
	}

	dataSets.dam_flat.push({
		name: "review",
		label: "Review",
		mIcon: ReviewIcon,
		show: () => {
			return (_.get(context, 'legacy.user.user_type') && context.legacy.user.user_type === 'user_admin');
		},
		items: reviewNavigationArray
	});



	const [state, setState] = useState({
		open: false,
		variant: (localStorage.getItem("sv-mosaic-left-nav-variant") !== null ? localStorage.getItem("sv-mosaic-left-nav-variant") : "full"),
		label: "Assets",
		name: "assets"
	});

	const variant = isMobile() ? "mobile" : state.variant;

	const onClick = function () {
		setState({
			...state,
			open: true
		});
	}

	const onClose = function () {
		setState({
			...state,
			open: false
		})
	}

	const onNav = function ({ item }) {
		const pageType = _.get(item, 'ui', 'transitional');
		const targetId = _.get(item, 'targetId', false);

		const newContext = {};

		if (pageType !== "external" && pageType !== "modal"){
			(new DisplayBridgeHelper).closeSubviews();
		}
		if(item.call !== undefined && (typeof item.call === 'function')) {
			item.call();
		}
		if (pageType === 'legacy') {
			if (_.get(item, 'targetId') === 'adminReports' && _.has(item, 'targetURL')) {
				document.getElementById('adminReportsContent').src = _.get(item, 'targetURL');
			}
			if(targetId !== false) {
				document.getElementById(targetId).style.display = 'initial';
			}
			document.getElementById('dataViewContainer').style.width = 'initial';
		} else if (pageType === 'external'){
			window.open(_.get(item, 'targetURL'), '_blank');
			return;
		} else if (pageType !== "modal") {
			document.getElementById('dataViewContainer').style.width = '100%';
		}
		if(pageType !== "modal") {
			newContext.currentPage = {
				name: item.name,
				type: pageType,
				id: targetId
			};
		}
		if(item.resetDownloadQueue && item.resetDownloadQueue === true) {
			newContext.alerts = {
				new: {
					downloads: []
				}
			};
		}
		if(item.resetReviewRequests && item.resetReviewRequests === true) {
			if(_.has('newContext', 'alerts.new')){
				newContext.alerts.new.download_requests = [];
			} else {
				newContext.alerts = {
					new: {
						download_requests: []
					}
				};
			}
		}
		newContext.useSavedViewSearchTerm = true;
		if(item.name==='assets') {
			newContext.resetAssetsPage = new Date();
		}
		UpdateSystemContext(newContext);

		setState({
			...state,
			open: false,
			label: item.label,
			name: item.name
		});
	}

	const onVariantChange = function (variant) {

		if(document.getElementById('damNavMenuStatus') !== null) {
			document.getElementById('damNavMenuStatus').value = variant;
			document.getElementById('damNavMenuStatus').dispatchEvent(new Event('change'));
		}



		localStorage.setItem("sv-mosaic-left-nav-variant", variant);

		setState({
			...state,
			variant,
			open: false
		});
	}

	useEffect(() => {
		document.body.className += ' react-left-nav';
		document.getElementById('adminCollectionReviewReact').addEventListener('click', () => {
			UpdateSystemContext({
				currentPage: {
					name: 'review.collections',
					type: 'transitional',
					id: false
				}
			});

			setState({
				...state,
				open: false,
				label: 'Collections',
				name: 'review.collections'
			});
		});

		document.getElementById('reactUpdateTriggerFlag').addEventListener('change', () => {
			context.getCollectionsForUser(_.get(context, 'legacy.user.id'));
		});

		if(document.getElementById('showUploadsPageTerms-bridge-nav-item') !== null) {
			document.getElementById('showUploadsPageTerms-bridge-nav-item').addEventListener('click', () => {
				UpdateSystemContext({
					currentPage: {
						name: 'uploadAssets2',
						type: 'legacy',
						id: 'uploadTermsAndConditions'
					}
				});
				document.getElementById('uploadTermsAndConditions').style.display = 'initial';
				document.getElementById('uploadTermsAndConditions').style.width = '100%';
				document.getElementById('assetUpload').style.display = 'none';
				document.getElementById('dataViewContainer').style.width = 'initial';
				setState({
					...state,
					open: false,
					label: "Upload Assets",
					name: 'uploadAssets',
					variant: (localStorage.getItem("sv-mosaic-left-nav-variant") !== null ? localStorage.getItem("sv-mosaic-left-nav-variant") : "full")
				});
			});
		}

		if(document.getElementById('requestAuthorizationReact-bridge-nav-item') !== null) {
			document.getElementById('requestAuthorizationReact-bridge-nav-item').addEventListener('click', () => {
				onNav({
					item: downloadRequestItem
				});
			});
		}

		if(document.getElementById('downloadQueue-bridge-nav-item') !== null) {
			document.getElementById('downloadQueue-bridge-nav-item').addEventListener('click', () => {
				onNav({
					item: downloadQueueItem
				});
			})
		}

		if(document.getElementById('showUploadsPage-bridge-nav-item') !== null) {
			document.getElementById('showUploadsPage-bridge-nav-item').addEventListener('click', () => {
				UpdateSystemContext({
					currentPage: {
						name: 'uploadAssets',
						type: 'legacy',
						id: 'assetUpload'
					}
				});
				handleAssetUploadClick();
				document.getElementById('assetUpload').style.display = 'initial';
				document.getElementById('uploadTermsAndConditions').style.display = 'none';
				document.getElementById('assetUpload').style.width = '100%';
				document.getElementById('dataViewContainer').style.width = 'initial';
				setState({
					...state,
					open: false,
					label: "Upload Assets",
					name: 'uploadAssets',
					variant: (localStorage.getItem("sv-mosaic-left-nav-variant") !== null ? localStorage.getItem("sv-mosaic-left-nav-variant") : "full")
				});
			});
		}

		if(document.getElementById('showBatchEditFilesPage-bridge-nav-item') !== null) {
			document.getElementById('showBatchEditFilesPage-bridge-nav-item').addEventListener('click', () => {
				UpdateSystemContext({
					currentPage: {
						name: 'batchEditAssets',
						type: 'legacy',
						id: 'adminReports'
					}
				});
				document.getElementById('adminReports').style.width = '100%';
				document.getElementById('dataViewContainer').style.width = 'initial';
				setState({
					...state,
					open: false,
					label: "Batch Edit Assets",
					name: 'review.files'
				});
			});
		}

		if(document.getElementById('showFilesReviewPage-bridge-nav-item') !== null) {
			document.getElementById('showFilesReviewPage-bridge-nav-item').addEventListener('click', () => {
				onNav({
					item: reviewFilesItem
				});

			})
		}

		if(document.getElementById('reactUpdatePage') !== null) {
			document.getElementById('reactUpdatePage').addEventListener('change', () => {
				UpdateSystemContext({
					currentPage: {
						name: 'review.users',
						type: 'transitional',
						id: false
					}
				});
				setState({
					...state,
					open: false,
					label: 'Users',
					name: 'review.users'
				});
			});
		}

		if(document.getElementById('triggerMenu') !== null) {
			document.getElementById('triggerMenu').addEventListener('click', () => {
				setState({
					...state,
					open: true,
					variant: 'hidden'
				});
			});
		}

	}, []);

	// add a resize listener for handling whether or not we are currently in mobile
	useEffect(() => {
		if(context.currentPage.name!=="assets"){
			context.updateContext({searchTerm:""});
		}

		const resizeHandler = debounce(function () {
			const shouldBeMobile = isMobile();

			// if we are in mobile, ensure we are, if we aren't mobile, ensure we aren't
			// triggers a re-render just by calling setState()
			if ((shouldBeMobile && variant !== "mobile") || (!shouldBeMobile && variant === "mobile")) {
				if(document.getElementById('damNavMenuStatus') !== null) {
					document.getElementById('damNavMenuStatus').value = 'mobile';
					document.getElementById('damNavMenuStatus').dispatchEvent(new Event('change'));
				}

				setState({
					...state
				});
			}
		}, 100);

		window.addEventListener("resize", resizeHandler);

		return function () {
			window.removeEventListener("resize", resizeHandler);
		}
	}, [state, variant]);

	// on item change scroll to the top
	const contentRef = useRef(null);
	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.scrollTo(0, 0);
		}
	}, [state.label, state.name]);

	useEffect(() => {
		if(document.getElementById('damNavMenuStatus') !== null) {
			document.getElementById('damNavMenuStatus').value = variant;
			document.getElementById('damNavMenuStatus').dispatchEvent(new Event('change'));
		}
	});

	// Filter to be applied to menu item set to run the show() method where available. Otherwise returns true.
	const filterMenuItemsSetToShow = r => {
		if(_.get(r, 'items', false) !== false) {
			r.items = r.items.filter(filterMenuItemsSetToShow);
		}
		return !_.get(r, 'show') || r.show();
	}

	const handleChange = e => {
		e.preventDefault();
	}
	return (
		<StyledDiv>
			<LeftNav
				active={context.currentPage.name}
				open={state.open}
				items={dataSets.dam_flat.filter(filterMenuItemsSetToShow)}
				variant={variant}
				onClose={onClose}
				onNav={onNav}
				onVariantChange={onVariantChange}
				zIndex={1250}
			/>
			<div className='nav-bridge'>
				<nav>
					<ul>{links}</ul>
				</nav>
				<input id='reactUpdateTriggerFlag' type='text' />
				<div id="adminCollectionReviewReact" className="item" adminpage="/"></div>
				<input id='reactReloadTransitionalPage' type='text' />
				<input id='reactUpdatePage' type='hidden' />
				<div id='showUploadsPage-bridge-nav-item'></div>
				<div id='showUploadsPageTerms-bridge-nav-item'></div>
				<div id='downloadQueue-bridge-nav-item'></div>
				<div id='triggerMenu'></div>
				<div id='showBatchEditFilesPage-bridge-nav-item'></div>
				<div id='showFilesReviewPage-bridge-nav-item'></div>
			</div>
		</StyledDiv>
	)
}