class DisplayBridgeHelper {

	/**
	 * This sets all elements with a class of 'subview' to display:none.
	 */
	closeSubviews(){
		for (let subview of document.getElementsByClassName('subview')) {
			subview.style.display = 'none';
		}
	}

	/**
	 * Set element with id of 'dataViewContainer' to be display: block;
	 */
	showDataViewContainer(){
		document.getElementById('dataViewContainer').style.display = 'block';
	}

}

export default DisplayBridgeHelper;