import React from 'react';
import { Button, theme } from "@simpleview/sv-mosaic";
import SystemContext, { UpdateSystemContext } from "../../SystemContext";
import DisplayBridgeHelper from '../../utils/helpers/DisplayBridgeHelper';
import styled from 'styled-components';
const _ = require('lodash');

import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Badge, withStyles } from '@material-ui/core';

export default function HeaderAlertMenu() {

	const context = React.useContext(SystemContext);

	const AlertDiv = styled.nav`
		display: flex;
		max-height: 100%;

		& button { color :#fff !important; }
		& button:hover { background: rgba(255, 255, 255, .3) !important; }

		& .badgeRoot { background-color: ${theme.colors.blue}; }
	`;

	const AlertMenuItems = [
		{
			label : `Download Queue : ${_.size(_.get(context,'alerts.new.downloads', []))}`,
			onClick : function() {
				if(document.getElementById('downloadQueue-bridge-nav-item') !== null) {
					document.getElementById('downloadQueue-bridge-nav-item').click();
				} else {
					document.getElementById('download-queue-nav-item').click();
				}

				UpdateSystemContext({
					alerts: {
						new: {
							downloads: []
						}
					},
					currentPage: {
						name: "download_queue",
						type: "legacy"
					}
				});
			}
		},
	];

	const AlertBadge = withStyles(() => ({
		badge: {
			backgroundColor: '#0070F2',
			top: '25%',
			right: '22%',
			margin: '0 auto',
			height: '12px',
			minWidth: '12px',
			padding: '0 2px'
		}
	}))(Badge);

	const BadgeCountDownloads = (_.size(_.get(context,'alerts.new.downloads', [])));
	let BadgeCountRequests = 0;

	if(_.get(context, "legacy.user.user_type") !== undefined){
		const isAdmin = ( context.legacy.user.user_type.match(/admin|master/i) ) ? true : false;
	 	if(isAdmin){
			BadgeCountRequests = (_.has(context,'alerts.new.downloads') && _.size(_.get(context,'alerts.new.download_requests', []))> 0) ? _.size(_.get(context,'alerts.new.download_requests', [])) : 0;

	 		AlertMenuItems.push({
				label : `Download Requests : ${_.size(_.get(context,'alerts.new.download_requests', []))}`,
	 			onClick : function() {
					if(document.body.classList.contains('react-left-nav') && document.getElementById('requestAuthorizationReact-bridge-nav-item') !== null) {
						const display = new DisplayBridgeHelper;
						display.closeSubviews();
						display.showDataViewContainer();
						document.getElementById('requestAuthorizationReact-bridge-nav-item').click();
						resetViewContainersForReact();
					} else {
						document.querySelector('[data-subnav-target="downloadRequests"]').classList.add('open');
						document.querySelector('[data-subnav="downloadRequests"]').classList.add('open');
						document.getElementById('requestAuthorizationReact').click();
					}
					UpdateSystemContext({
						alerts: {
							new: {
								download_requests: []
							}
						},
						currentPage: {
							name: "settings.downloadRequests.reviewRequests",
							type: "transitional"
						}
					});
	 			}
	 		});
	 	}
	}

	const BadgeCount = BadgeCountDownloads + BadgeCountRequests;
	const mIcon = ( BadgeCount > 0 ) ? NotificationsIcon : NotificationsNoneIcon;
	const CustomToolTip = `${BadgeCount} New Notification${BadgeCount !== 1 ? 's' : ''}`;

	return (
		<AlertDiv>
			<AlertBadge badgeContent={BadgeCount} max={99} overlap="circular" >
				<Button
					color = "blue"
					variant = "icon"
					mIcon = {mIcon}
					menuItems = {AlertMenuItems}
					tooltip = {CustomToolTip}
				/>
			</AlertBadge>
		</AlertDiv>
	);

}