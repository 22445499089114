import React, { useContext, useEffect, useRef, useState } from "react";
import DamForm from "@simpleview/sv-dam-form";
import DamDrawer from "../DamDrawer";
import { handle_dam_error } from '../utils/libs/errorLib';
import { logoutWhereSessionHasExpired } from "../utils/libs/graphLib";


import SystemContext, { UpdateSystemContext } from "../SystemContext";

const _ = require('lodash');
import { Button, theme } from "@simpleview/sv-mosaic";
import styled from 'styled-components';

export default function DrawerNotificationAddEdit(props){
	const DamFormDrawerContentWrapper = styled.div`
		display: block;
		height: calc(100vh - 84px);
		position: relative;

		& > .damFormBody{
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			color: ${theme.colors.gray400}
			font-family: ${theme.fontFamily}
		}

		& > .damFormBody p {
			font-weight: 500;
			padding: 10px 0 30px;
		}
	`;

	const context = React.useContext(SystemContext);

	const users = context.platform.admin_users.map(u => {
		return {
			value: u.id,
			label: `${u.first_name} ${u.last_name} (${u.email})`,
		}
	});

	const roles = context.platform.user_roles.map(r => {
		return {
			value: r.name,
			label: r.label
		};
	});
	roles.push({value: 'default', label: 'Default'});

	const upsertData = async data => {
		const { NotificationsPrefix } = require("@simpleview/sv-dam-client");
		const { GraphServer } = require("@simpleview/sv-graphql-client");
		const dam = new GraphServer({ graphUrl : "/ajax/graph.php", prefixes : [NotificationsPrefix] });

		const input = {
			user_id : Number(data.user),
			type : 'NEW_USER_SIGNUP',
			active : true
		}
		if(data.role !== 'default') {
			input.args = { role : data.role };
		}
		const initialData = _.get(context,'drawers.notificationAddEdit.data');
		if(initialData !== null) {
			input.id = initialData.id;
		}

		const clientReturn = await dam.notifications.upsert({
			fields : `
				success
				message
			`,
			context : {},
			input,
			headers : "headers"
		})
		.catch((err) => {
			if(logoutWhereSessionHasExpired(err)) {return;}
			return err;
		});

		if(_.get(clientReturn, 'success') === true){
			const newContext = {
				...context
			};
			newContext.drawers.notificationAddEdit.open = false;
			newContext.drawers.notificationAddEdit.updated = true;

			UpdateSystemContext(newContext);
		}else{
			handle_dam_error({
				public : "Unable to update notification(s).",
				debug : "GraphlQL Client update to notifications_upsert failed",
				data : {
					clientReturn,
					args : input
				}
			});
		}
	}

    const formConfig = {
        onSubmit : upsertData,
        sections : [
          {
            fields : [
				{
					name : 'user',
					type : "filterSingleSelect",
					label : 'User',
					validation : {
					required : true,
					errors : {
					  required : "Choose a user"
					  }
					},
					unselected : "Select a user",
					options : users,
					placeholder : 'Select a user'
				},
				{
					name : 'role',
					type : 'filterSingleSelect',
					label : 'Role',
					validation : {
					required : true,
					errors : {
					  required : "Choose a role"
					  }
					},
					unselected : "Select a role",
					options : roles,
					placeholder : 'Select a role'
				}
            ]
          },
       ]
      };
	  const initialData = _.get(context,'drawers.notificationAddEdit.data');
	  if(initialData !== null) {
		formConfig.defaultValues = {
			user : initialData.user.id,
			role : initialData.argsRole
		};
		formConfig.title = 'Edit Notification';
	  }

	return (
		<DamDrawer
		open={true}
		title={translate("Add Notification")}
		onClose={props.onClose}
>
			<DamFormDrawerContentWrapper>
				<div className='damFormBody'>
					<DamForm
					config={formConfig}
					/>
				</div>
			</DamFormDrawerContentWrapper>
		</DamDrawer>
	);
}