//stub to be expanded later
export function handle_dam_error(err){
	if(err.debug === undefined || err.data === undefined || err.public === undefined ){ throw new Error("Invalid error format"); }
	const extractedErrorData = (err.data !== undefined && err.data.err !== undefined) ? err.data.err : [];

	let errStructure = {
		container : "ui",
		env: document.getElementById('siteHeaderContainer').getAttribute('data-current-env'),
		data : err,
		error : extractedErrorData
	}

	if (err.assetOwner !== undefined) {
		errStructure.assetOwner = err.assetOwner;
	}

	if (err.domain !== undefined) {
		errStructure.domain = err.domain;
	}

	//send to loggly
	if(_LTracker !== undefined){
		_LTracker.push(errStructure);
	}

	if(document.getElementById('siteHeaderContainer').getAttribute('data-current-env') !== 'live'){//check needs to move to direct env var check
		let errMsg = err.public;
		if (errMsg.match(/(Request failed)(\s{1}[[]\d{1,}[]])?/i)) {
			errMsg = "Request failed\nIf the issue persists, please logout, clear your cache, refresh the page, and login again.";
		}
		alert(errMsg);
		console.log(err.debug, err.data);
	}else{
		let errMsg = err.public;
		if (errMsg.match(/(Request failed)(\s{1}[[]\d{1,}[]])?/i)) {
			errMsg = "Request failed\nIf the issue persists, please logout, clear your cache, refresh the page, and login again.";
		}
		alert(errMsg);
		console.log(err.public);
	}
}