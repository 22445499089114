import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SystemContext, { UpdateSystemContext } from "./SystemContext";

/*
	This component is intended to allow implementation of browserrouter items without having to move all of the site into react.
	-- map for nav items to intercept --
	1. tag the link you want to intercept with a unique id then add that as the interceptTarget
	2. define a new route for the browserrouter in /ui/src/index.js and add that as the path
	3. add a redirect in .htaccess for the path
*/
const navMap = [
	{
		interceptTarget : 'all-assets-nav-item',
		path : '/'
	},
	{
		interceptTarget : 'adminAccessGroupsReact',
		path : '/admin/accessGroups'
	},
	{
		interceptTarget : 'admin-users-nav-item',
		path : '/admin/adminUsers'
	},
	{
		interceptTarget : 'adminCollectionReviewReact',
		path : '/admin/collections'
	},
	{
		interceptTarget : 'users-nav-item',
		path : '/admin/users'
	},
	{
		interceptTarget : 'admin-users-files-item',
		path : '/admin/files'
	},
	{
		interceptTarget : 'admin-user-uploads',
		path : '/admin/users/uploads'
	},
	{
		interceptTarget : 'adminNotificationsReact',
		path : '/admin/notifications/newUser'
	},
	{
		interceptTarget : 'requestAuthorizationReact',
		path : '/admin/users/downloadRequests'
	},
	{
		interceptTarget : 'categoriesReact',
		path : '/admin/tags/asset'
	},
	{
		interceptTarget : 'user-uploads-nav-item',
		path : '/user/uploads'
	}
]

export default function DamNavBridge(props) {
	const sysContext = useContext(SystemContext);

	const StyledNavBridge = styled.nav`
		display: none;
		font-size: .0000001em;
		position: absolute;
		top: -99999999999999999px;
		left: -99999999999999999px;
		color: rgba(0, 0, 0, .00000001);

		& a { color: rgba(0, 0, 0, .00000001); }
	`;

	const links = navMap.map( (navitem, i) => {
		if(document.getElementById(navitem.interceptTarget) === null){ return; }

		const bridgeNavId = navitem.interceptTarget + '-bridge-nav-item';

		//eliminate default behavior
		document.getElementById(navitem.interceptTarget).removeAttribute("adminpage");

		//intercept clicks on legacy elements
		document.getElementById(navitem.interceptTarget).addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();
			document.getElementById(bridgeNavId).click();
			resetViewContainersForReact(); //defined in main5_js.php
		});

		return <li key={i}><Link to={navitem.path} id={bridgeNavId}>{bridgeNavId}</Link></li>
	});

	useEffect(() => {
		if( document.getElementById('download-queue-nav-item') !== null ){
			document.getElementById('download-queue-nav-item').addEventListener('click', (e) => {
				//clear new download notices
				UpdateSystemContext({
					alerts : {
						new : {
							downloads : []
						}
					}
				});
			});
		}

		if( document.getElementById('requestAuthorizationReact') !== null ){
			document.getElementById('requestAuthorizationReact').addEventListener('click', (e) => {
				//clear new download notices
				UpdateSystemContext({
					alerts : {
						new : {
							download_requests : []
						}
					}
				});
			});
		}
		if( document.getElementById("reactQueueInfo") !== null ){
			document.getElementById('reactQueueInfo').addEventListener('queueUpdateEvent', (e) => {
				e.preventDefault();
				e.stopPropagation();

				const newQueue = e.detail.queue.map( (d) => { return d.video_id; } );
				const newContext = {
					legacy : { download_queue : e.detail.queue },
					alerts : {
						all : {
							downloads : newQueue
						}
					}
				};
				UpdateSystemContext(newContext);
			});
		}
		bs.queue.updateCount();

	}, []);

	return (
		<StyledNavBridge>
			<ul>
				{links}
			</ul>
		</StyledNavBridge>
	);

}