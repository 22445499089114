import ReactDOM from "react-dom";
import React from "react";
import {
	Drawer,
	DrawerContent
} from "@simpleview/sv-mosaic"

export default function DamDrawer(props) {
	return (
		<Drawer
			open={props.open}
		>
			<DrawerContent
				title={props.title}
				onClose={props.onClose}
			>
				{props.children}
			</DrawerContent>
		</Drawer>
	);
}