import React from "react";
import { Button, theme } from "@simpleview/sv-mosaic";
import LinearProgressWithLabel  from './LinearProgressWithLabel';


import styled from 'styled-components';

export default function UploadProgressIndicator(props) {

	const UploadProgressIndicatorWrapper = styled.div`
		border-radius: 8px;
		box-shadow: rgba(0.5, 0.5, 0.5, 0.3) 2px 3px 7px;
		display: flex;
		margin-bottom: 10px; /* For demo purposes only */
		max-height: 96px;
		overflow: hidden;
		width: 100%;
		position: fixed;
		bottom: 33px;
		left: 27px;
		width: 405px;
		background-color: white;

		& > .rest {
			flex: 1;
			padding: 0.5em 0.8em 0.5em 1.5em;

			& > .top {
				align-items: center;
				display: flex;
				justify-content: space-between;

			}
		}

		& h6 {
			color: #aaa;
			letter-spacing: 1px;
			margin: 0;
		}

		& .counter {
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 0.5em;
			margin-top: -5px;
		}


	`;

	const ImageWrapper = styled.div`
		align-items: center; 
		display: flex;
		flex-direction: column;
		justify-content: center; 
		max-width: 120px;
		max-height: 96px;
		overflow: hidden;
		
		& > img {
			width: auto;
			min-width: 96px;
			max-width: 120px;
			max-height: 96px;
		}
	`;

	return (
		<UploadProgressIndicatorWrapper onClick = {props.myUploadsHandler}>
			{props.thumbnail && 
				<ImageWrapper>
					<img src={props.thumbnail} height="96" alt="Uploading asset" />
				</ImageWrapper>
			}
			
			<div className="rest">
				<div className="top">
					<h6>UPLOADING</h6>
					<div>
						<Button  
							color = "blue"
							variant = "text"
							label = "Cancel"
							onClick = {props.onCancelClick}
							tooltip = "Cancel upload"
						/>
					</div>
				</div>
				
					<div className="counter">
						{props.currentItem} of {props.totalItems}
					</div>
					{props.currentProgress >=0 ? <LinearProgressWithLabel value={parseInt(props.currentProgress)} /> : props.currentProgress}
			</div>
		</UploadProgressIndicatorWrapper>
	);
}