import React, { useState } from "react";
import styled from 'styled-components';

export default function PlatformSwitcher(props) {

	const [menuStatus, setMenuStatus] = useState(false);

	const StyledDiv = styled.div`
		& div.selected-platform-label {
			cursor: pointer;
			padding: 10px 20px;
		}

	`;

	const ListWrapper = styled.div`
		background-color: black;
		max-height: calc(100vh - 200px);
		overflow-y: auto;
		overflow-x: hidden;
		position: absolute;
		z-index: 5;

		& div {
			cursor: pointer;
			padding: 10px 20px;
		}

		& ul {
			background-color: black;
			display: table;
			margin-bottom: 0px;
			padding: 0px;
			width: 100%;
		}

		& li {
			cursor: pointer;
			display: table-row;
			list-style:none;
			padding: 10px 20px;
			position: relative;
			white-space: nowrap;
			z-index: 1210;
		}

		& li:hover {
			background-color: #0070F2;
		}
	`;

	const Arrow = styled.div`
		display: inline-block;
		height: 11px;
		margin-left: 20px;
		padding: 0px!important;
		width: 9px;

		& span {
			border: solid #fff;
			border-width: 0 1px 1px 0;
			display: inline-block;
			font-size: 1em;
			height: 8px;
			line-height: 1;
			position: absolute;
			transform: rotate(45deg);
			width: 8px;
			}
	`;

	const toggleMenu = function (state) {
		if (state !== undefined) {
			setMenuStatus(state);
			return;
		}
		setMenuStatus(!menuStatus);
	}

	const assetOwners = { ...props.assetOwners };
	const currentAssetOwner = props.currentAssetOwner;
	const currentAssetOwnerId = assetOwners[currentAssetOwner].id;
	delete assetOwners[currentAssetOwner];

	let assetOwnersArray = [];
	for (let [key, value] of Object.entries(assetOwners)) {
		assetOwnersArray.push({ id: value.id, name: value.name });
	}

	const switchAssetOwner = function (id) {
		bs.s.show(translate("Switching platforms..."), 'info');
		window.location.href = '/platformSwitch.php?aoId=' + id + '&currentAoId=' + currentAssetOwnerId;
	}

	const assetOwnersList = assetOwnersArray.map((assetOwner) =>
		<li key={assetOwner.id} onClick={switchAssetOwner.bind(this, assetOwner.id)}><div>{assetOwner.name}</div></li>
	);

	return (
		<div
			onClick={() => toggleMenu()}
			onFocus={() => toggleMenu()}
			onBlur={() => toggleMenu(false)}
			tabIndex="0"
		>
			<StyledDiv>
				<div className='selected-platform-label'>{currentAssetOwner}<Arrow><span></span></Arrow></div>
				{menuStatus && (
					<ListWrapper>
						<ul>
							{assetOwnersList}
						</ul>
					</ListWrapper>
				)}
			</StyledDiv>
		</div>
	);
}