import React, { useState } from 'react';
import styled from 'styled-components';

export default function LanguageSelector(props) {

	const handleClick = function(lang, e) {
		e.preventDefault();
		if(currentLanguage === lang) {
			return;
		}
		bs.translate.change(lang); 
	}

	const StyledLanguageButtons = styled.ul`
		display: inline-flex;
		font-size: 0.9em;
		list-style: none;
		margin: 0 5px;

		& a { 
			color: #999;
			cursor: pointer;
		}

		& li {
			margin-left: 0.5em;
		}

		& li:not(:last-child)::after {
			color: #999;
			content: "/";
			font-weight: normal;
			margin-left: 0.5em;
		}

		& .selected-language { 
			font-weight: bold; 

			& a {
				color: #333;
				cursor: auto;
				text-decoration: none;
			}
		}
	`;
	const currentLanguage = props.currentLanguage;
	const languages = props.languages;
	const languageButtons = languages.map((language) => 
		<li key={language} className={`${language === currentLanguage ? 'selected-language' : ''}`}>
			<a onClick={handleClick.bind(this, language)} data-id={language}>{language === "jp" ? "日本語" : language.toUpperCase()}</a>
		</li>
	);

	return (
		<StyledLanguageButtons>{languageButtons}</StyledLanguageButtons>
	);
	
}