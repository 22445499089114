import React from "react";
import ReactDOM from "react-dom";
import styled from 'styled-components';

import LanguageSelector from './LanguageSelector';

export default function DamFooter(props) {
	const StyledFooter = styled.footer`
		background-color: white;
		border-top: 1px solid #eee;
		bottom: 0;
		display: flex;
    	flex-direction: column;
		height: 68px;
		justify-content: flex-end;
		padding: 0.8em 1.3em;
		position: absolute;
		right: 0;
		width: calc(100% - 230px);
		z-index: 1040;
		
		& > div {
			align-self: flex-end;
		}

		& > div > p{
			color: #999;
			font-size: 0.9em;
			margin-bottom: 0;
		}

		& > div > p > a{ color: #999; }

		& > div > p > a:hover{ text-decoration: underline;}

		& > div.right { 
			display:flex; 
			justify-content: flex-end;
		}

		& :last-child { margin-right: 0 !important; }

	`;

	const languages = props.languages.split(',');
	const currentLanguage = props.currentLanguage;

	return (
		<StyledFooter>
			<div className="right">
				<LanguageSelector languages={languages} currentLanguage={currentLanguage} className="language" /> 			</div>
			<div dangerouslySetInnerHTML={{ __html: translate("<p>For technical support, please email: <a href='mailto:damsupport@simpleviewinc.com' target='_blank'>damsupport@simpleviewinc.com</a>. © 2020 Simpleview Inc.</p>") }} />
		</StyledFooter>
	);
}