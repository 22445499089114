import "@babel/polyfill";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { SystemContextProvider } from "../lib/webroot/www/react/src/SystemContext.js";
import DamComponentLoader from "../lib/webroot/www/react/src/utils/DamComponentLoader";

import DamDrawers from "../lib/webroot/www/react/src/DamDrawers";
import DamHeader from "../lib/webroot/www/react/src/DamHeader";
import DamSideNav from  "../lib/webroot/www/react/src/DamSideNav";
import DamFooter from "../lib/webroot/www/react/src/DamFooter";
import DamNavBridge from "../lib/webroot/www/react/src/DamNavBridge";
import ScrollToTop from "../lib/webroot/www/react/src/ScrollToTop";

const DataViewDamGrid = DamComponentLoader({ component : 'allAssets' });

const dataViewElement = document.getElementById('dataViewContainer');
if(dataViewElement !== null && !dataViewElement.hasAttribute('data-public-page')){
	
	ReactDOM.render(
		<SystemContextProvider>
			<DamHeader currentUser={document.getElementById("siteHeaderContainer").getAttribute("data-current-user")} />
			<DamDrawers />
			<BrowserRouter>
				<DamSideNav/>
				<ScrollToTop/>
				<DamNavBridge/>
					<Switch>	
						<Route path="/admin/accessGroups" component={ DamComponentLoader({ component : 'accessGroups' }) }></Route>		
						<Route path="/admin/adminUsers" component={ DamComponentLoader({ component : 'adminUsers' }) }></Route>
						<Route path="/admin/collections" component={ DamComponentLoader({ component : 'collectionsReview' }) }></Route>
						<Route path="/admin/tags/asset" component={ DamComponentLoader({ component : 'assetTags' }) }></Route>		
						<Route path="/admin/users/downloadRequests" component={ DamComponentLoader({ component : 'downloadRequests' }) }></Route>
						<Route path="/admin/users/uploads" component={ DamComponentLoader({ component : 'userUploads' }) }></Route>
						<Route path="/admin/users" component={ DamComponentLoader({ component : 'users' }) }></Route>
						<Route path="/admin/files" component={ DamComponentLoader({ component : 'files' }) }></Route>
						<Route path="/admin/notifications/newUser" component={ DamComponentLoader({ component : 'newUserNotifications' }) }></Route>
						<Route path="/user/uploads" component={ DamComponentLoader({ component : 'myUploads' }) }></Route>
						<Route path="/">
							<DataViewDamGrid
								currentLanguage={dataViewElement.getAttribute('data-current-language')}
								customView={dataViewElement.getAttribute('data-custom-view')}
							/>
						</Route>
					</Switch>
			</BrowserRouter>
		</SystemContextProvider>,
		dataViewElement
	);
} else if(document.getElementById('legacyReactContainer') !== null){
	const dataViewElement = document.getElementById('legacyReactContainer');
	ReactDOM.render(
		<SystemContextProvider>
			<DamDrawers />
		</SystemContextProvider>,
		dataViewElement
	);
}

if(document.getElementById('siteFooter') !== null){
	const footerElement = document.getElementById('siteFooter');
	if(footerElement !== null){
		ReactDOM.render(
			<DamFooter languages={footerElement.getAttribute('data-languages')} currentLanguage={footerElement.getAttribute('data-current-language')}/>,
			footerElement
		);
	}
}