import React, { useContext, useState } from "react";

import styled from 'styled-components';
import DamForm from "@simpleview/sv-dam-form";
import axios from 'axios';
const _ = require('lodash');

import SystemContext from "../SystemContext";

export default React.memo(function ContactUsDrawerContent(props){
	const sysContext = useContext(SystemContext);
	const [sentState, setSentState] = useState({sent: false, error: false});


	const ContactUsDrawerContentWrapper = styled.div`
		display: block;
		height: calc(100vh - 84px);
		position: relative;
	`;

	const handleFormSubmission = async data => {
		setSentState({sent: false, error: false}); // Reset

		const contacts = _.get(sysContext, 'legacy.user.custom_options.contacts.quickContact', false);

		const sent = await axios.post('/ajax/send_email.php', {
			to: contacts,
			from: data.from,
			body : `${data.message}`
		});

		if(sent.data.success === false) {
			setSentState({sent: false, error: true});
			document.getElementsByName('from')[0].value = data.from;
			document.getElementsByName('message')[0].value = data.message;
		} else {
			setSentState({sent: true, error: false});
		}
	};

	const formConfig = {
        onSubmit : handleFormSubmission,
        sections : [
          {
            fields : [
				{
					name : 'to',
					type : 'text',
					label : 'To',
					disabled: true,
					placeholder : 'Support'
				},
				{
					name : 'from',
					type : 'text',
					label : 'From',
					validation : {
						required : true,
						minLength : 6,
						maxLength : 250,
						errors : {
							required : "From is a required field. Please fill it out.",
							minLength : "Please enter a longer email address.",
							maxLength : "The email is too long."
						}
					},
					placeholder : 'Your email'
				},
				{
					name : 'message',
					type : 'textarea',
					label : 'Message',
					validation : {
						required : true,
						minLength : 20,
						maxLength : 2000,
						errors : {
							required : "Message is a required field. Please fill it out.",
							minLength : "Please enter a longer message.",
							maxLength : "Your message is too long."
						}
					},
					placeholder : 'Please enter your message'
				}
            ]
		  }
		]
      };

	return (
		<ContactUsDrawerContentWrapper>
			<DamForm
				config={formConfig}
				sentState={sentState}
			/>
		</ContactUsDrawerContentWrapper>
	);
})