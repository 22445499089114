import React from "react";
import DamDrawer from "../DamDrawer";
import DamDrawerContactUsContent from "./DamDrawerContactUsContent";

export default React.memo( function DrawerContactUs(props) {

	return (
		<DamDrawer
			open={true}
			title={translate("Contact Us")}
			onClose={props.onClose}
		>
			<DamDrawerContactUsContent />
		</DamDrawer>
	);
})