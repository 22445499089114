import React, { useState } from 'react';
import styled from 'styled-components';

export default function Login() {

	const LoginDiv = styled.div`
		display: flex;
		max-height: 100%;

		& .login-area {
			display: flex;
			color: inherit;
			/* deal with issues in customized css files. can be removed when they are no longer loaded */
			margin: 0 !important;
			padding: 0;
			max-width: 100% !important;
			background: none;
		}

		& .login-creds .name {
			display: none;
		}

		& .login-links {
			display: flex;
			position: relative;
			top: 0;
			margin-top: 0;		
			align-items: center;
			width: auto;
			font-size: 1.2em; 			
		}

		& .login-links a { 
			align-items: center;
			margin: 0 10px;
			color: rgb(170, 170, 170); 	
		}

		& .inputContainer { 
			display: flex;
			align-items: center;
			justify-content: center;
			width: auto;
			max-height: 100%;
			overflow: hidden;
		}

		& .inputContainer button {
			background-color: inherit !important;
			border: none !important;
			border-radius: 50% !important;
			color: #848791 !important;
			flex-direction: column;
			height: 2.3em!important;
			justify-content: center;
			outline: none;
			padding: 0.3em 0.3em 0.3em 0.5em !important;
			position: absolute;
			right: 40px!important
			top: inherit !important;
			width: 2.3em !important;

			&:hover {
				background-color: #000 !important;
			}
		}

		& .inputContainer input {
			display: flex;
			background-color: rgba(255,255,255,0.2);
			border: 1px solid transparent;
			border-radius: 0.3em;
			color: #aaa;
			font-size: 1.2em;
			outline: none;
			padding: 10px 15px;
			width: 10em;
			margin: 0 10px 0 auto;

			&:focus {
				background-color: #eee;
				color: #333;
				width: 10em;

				&::placeholder {
					color: #333;
				}
			}

			&::placeholder {
				color: #ccc;
			}
		}

		& #btnLogout, 
		& .platform-button, 
		& .platform-button-bordered {
			color: #fff;
			background-color: black;
			border: 1px solid rgba(255, 255, 255, 0.5);
			border-radius: 5px;
			float: none;
			font-size: 14px;
			padding: 10px 15px;
		}

		& #btnLogout{
			margin-top: -2px;
			padding: 10px 15px 11px 15px;
		}

		& #btnLogin,
		& #btnLogout{
			font-size: 1.2em;
			margin-top: 0px; /* Override bbs legacy */
			width: auto;
		}

		& #btnLogout:hover, 
		& #btnLogout:focus, 
		& .platform-button:hover, 
		& .platform-button-bordered:hover {
			background-color: white;
			color: black;
		}

	`;

	return (
		<LoginDiv>
			<div className="login-area">
				<div className="login-links">
					<a href="#" id="_register">{translate('Register')}</a> | <a href="/forgot-password">{translate('Forgot Password')}</a>
				</div>
				<div className="login-creds inputContainer">
					<input type="text" id="username" placeholder={translate('Username')} />
					<input type="password" id="password" placeholder={translate('Password')} />
				</div>
				<button id="btnLogin" className="platform-button">{translate('Login')}</button>
				<button id="btnLogout" tabIndex="0">{translate('Logout')}</button>
			</div>
		</LoginDiv>
	);
	
}