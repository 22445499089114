import React, { useState, useEffect } from "react";
import axios from 'axios';
const _ = require('lodash');
import { Button, theme } from "@simpleview/sv-mosaic";
import { mdiFilePlusOutline } from '@mdi/js';
import Icon from '@mdi/react';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import styled from 'styled-components';
import UploadProgressIndicator from "./UploadProgressIndicator";
import { isError } from "lodash";

let abortUpload = false;
const defaultData = {
	file_list : [],
	current_item : 0,
	in_progress : false,
	uploading: false,
	current_thumbnail : ''
}
let data = defaultData;

export default function UploadDrawerContent(props){
	const UploadDrawerContentWrapper = styled.div`
		display: block;
		height: calc(100vh - 84px);
		position: relative;

		& > .uploadDropzone{
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			color: ${theme.colors.gray400}
			font-family: ${theme.fontFamily} 
		}

		& > .uploadDropzone .upload_icon{
			margin-top: calc(50vh - 160px);
		}

		& > .uploadDropzone p { 
			font-weight: 500; 
			padding: 10px 0 30px;
		}
	`;

	const [state, setState] = useState({
		uploading: false,
		currentProgress : 0,
		currentThumbnail : "/img/file-icons/unknown.png",
		currentFile : 0,
		totalItems : 0
	});

	const readFileAsync = function({file, readMode}) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = reject;

			switch(readMode){
				case 'buffer':
					reader.readAsArrayBuffer(file);
					break;
				case 'dataUrl':
					reader.readAsDataURL(file);
					break;
			}
			
		})
	};

	const dropHandler = function(evt){
		evt.preventDefault();
		evt.stopPropagation();

		let files = ( _.has(evt, 'dataTransfer.items') === true ) ? evt.dataTransfer.items : evt.dataTransfer.files;
		files = [...files];

		queueFiles(files);
	}

	const dragOverHandler = function(evt){
		evt.preventDefault();
		evt.stopPropagation();
	}

	const dragEnterHandler = function(evt){
		evt.preventDefault();
		evt.stopPropagation();
	}

	const dragLeaveHandler = function(evt){
		evt.preventDefault();
		evt.stopPropagation();
	}

	const handleBrowseButtonClick = function(evt){
		evt.preventDefault();
		evt.stopPropagation();

		//build a file input
		const browse = document.createElement('input');
		browse.type = 'file';
		browse.multiple = true;

		//handler for files selected
		browse.onchange = (evt) => {

			let files = evt.target.files;
			files = [...files];

			queueFiles(files);
		}

		browse.click();
	}

	const queueFiles = (files) => {
		if( data.uploading === false && data.file_list.length > 0 ){
			data.file_list = [];
			data.current_item = 0;
			data.in_progress = false;
			data.uploading = false;
			data.current_thumbnail = '';
		}

		for(let fileCtr = 0; fileCtr < files.length; fileCtr++){
			data.file_list.push(files[fileCtr]);
		}

		data.uploading = true;

		setState({
			currentFile : data.current_item + 1,
			totalItems : data.file_list.length,
			currentProgress: 'Adding Additional Files To Queue',
			currentThumbnail : data.current_thumbnail,
			uploading : data.uploading
		});
	}

	const _handleLoopError = (message) => {
		// console.log("handle loop error", message);

		abortUpload = true;

		data.file_list = [];
		data.current_item = -1;
		data.in_progress = false;
		data.uploading = false;
		data.current_thumbnail = '';

		setState({
			currentFile: 0,
			totalItems: 0,
			currentProgress: message,
			currentThumbnail : data.current_thumbnail,
			uploading : false
		});

		alert(message);
	}

	useEffect(() => {
		if( state.uploading === false || data.in_progress === true ){ return; }

		data.in_progress = true;

		const uploadFiles = async function() {
			abortUpload = false;
			while(data.current_item < data.file_list.length){

				if(abortUpload === true){ 
					data.uploading = false;
					data.in_progress = false;

					setState({
						currentFile : 0,
						totalItems : 0,
						currentProgress : "Cancelled",
						currentThumbnail : "",
						uploading : data.uploading
					});

					data = defaultData
					break; 
				}
				let thumbnail = "/img/file-icons/unknown.png";

				const file = data.file_list[data.current_item];
				const file_name = file.name;
				const file_type = (file.type !== '') ? file.type : 'unknown';
				const file_size = parseInt(file.size);

				if ( /\.(jpe?g|png|gif)$/i.test(file.name) && file_size <= 50000000 ) { //common image of reasonable size
					thumbnail = await readFileAsync({file, readMode : 'dataUrl'})
											.catch((err) => {thumbnail = "/img/file-icons/unknown.png";});
				} else {
					if( file_type.includes('video') || file_type.includes('image') ) {
						thumbnail = "/css/barberstock/placeholder.png"
					} else if ( file_type.includes('audio') ){
						thumbnail = "/img/audio_file.png"
					}
				}

				data.current_thumbnail = thumbnail;

				setState({
					currentFile : data.current_item + 1,
					totalItems : data.file_list.length,
					currentProgress: 'Reading File',
					currentThumbnail : data.current_thumbnail,
					uploading : data.uploading
				});

				let uploadStartResult;
				const fileData = await readFileAsync({file, readMode : 'buffer'})
											.catch((err) => {	
												_handleLoopError(`Error reading ${file_name}. (${err.message})`);
												abortUpload = true;
											});
			
				setState({
					currentFile : data.current_item + 1,
					totalItems : data.file_list.length,
					currentProgress: 'Starting Upload',
					currentThumbnail : data.current_thumbnail,
					uploading : data.uploading		
				});

				await axios.post(`/ajax/dataview_files_upload.php?file_name=${file_name}&file_type=${file_type}&file_size=${file_size}`)
				.then(data => {
					uploadStartResult = data;
				})
				.catch(err => {
					_handleLoopError(`Error reading ${file_name}. (${err.message})`);
				});

				if (_.has(uploadStartResult, 'data.data.bbs.upload_start.upload_values.key')) {
					const upload_values = uploadStartResult.data.data.bbs.upload_start.upload_values;
					const upload_key = upload_values.key;
	
					const upload_chunk_size = (_.has(upload_values, 'chunk_size') ? upload_values.chunk_size : 0);
					const total_chunks = upload_values.total_chunks;
					
					let next_chunk = 1; // there will always be at least 1 chunk to start
					const buf = new Uint8Array(fileData);
					let error = false;
					let uploadChunkResult;
					let uploadSuccess = false;					
	
					while (next_chunk !== null && !error && !abortUpload) {						
						let formData = new FormData();
						let upload_chunk = buf.subarray((next_chunk - 1) * upload_chunk_size, next_chunk * upload_chunk_size);
						formData.append(
							'file',
							new Blob([upload_chunk]),
							file.name
						);
	
						// Calculate upload progress
						const currentProgress = parseInt(((next_chunk - 1) / total_chunks) * 100, 10);
	
						setState({
							currentFile : data.current_item + 1,
							totalItems : data.file_list.length,
							currentProgress,
							currentThumbnail : data.current_thumbnail,
							uploading : data.uploading				
						});
	
						formData.append('upload_chunk_id', next_chunk);
						formData.append('upload_key', upload_key);
	
						// Try to upload the chunk multiple times before failing
						let uploadAttempts = 0;
						uploadSuccess = false;
						while (uploadAttempts < 3 && !uploadSuccess && !abortUpload) {
							let axiosError = null;
							await axios.post(
								`/ajax/dataview_files_upload_chunk.php`,
								formData,
								{
									headers: {
										'Content-Type': 'multipart/form-data'
									}
								}
							)
							.then(data => {
								uploadChunkResult = data;
							})
							.catch(err => {
								// Do nothing. We'll handle it in the next loop
								console.log('CAUGHT: Communication error. Trying to chunk', err);
								axiosError = err.message;
							});
							next_chunk = _.get(uploadChunkResult, 'data.data.bbs.upload_chunk.document.next_chunk', null);
							uploadSuccess = _.get(uploadChunkResult, 'data.data.bbs.upload_chunk.success', false);
							// console.log(upload_key,next_chunk,'attempt: '+(uploadAttempts+1)+'; success? '+uploadSuccess);
							if(!uploadSuccess) {
								var uploadError = _.get(uploadChunkResult, 'data.error', axiosError);
								console.log(`Upload error: (${uploadError})`);
							}
							uploadAttempts++;									
						}
					}
	
					if (uploadSuccess) {
						// Try to end the chunked upload multiple times before failing
						let finishAttempts = 0;
						let finishedSuccess = false;
						while (finishAttempts < 3 && !finishedSuccess && !abortUpload) {
							// Call upload_end
							let uploadEndResult;
							await axios.post(`/ajax/dataview_files_upload_end.php?upload_key=${upload_key}`)
							.then(data => {
								uploadEndResult = data;
							})
								.catch(err => {
									// Do nothing. We'll handle it in the next loop
									console.log('CAUGHT: Communication error. Trying to end');
								});
							if (_.has(uploadEndResult, 'data.data.error')) {
								error = true;
								_handleLoopError(`Upload failed. (${uploadEndResult.data.data.error})`);
							} else if (!_.get(uploadEndResult, 'data.data.bbs.upload_end.success', false)) {
								let errorMessage = _.get(uploadEndResult, 'data.data.bbs.upload_end.message', 'Unknown error');
								error = true;
								_handleLoopError(`Error: upload_end failed. (${errorMessage})`);
							}
	
							if (!error && !abortUpload) {						
								
								if(data.current_item + 1 === data.file_list.length) {
									data.uploading = false;

									setState({
										currentFile : 0,
										totalItems : 0,
										currentProgress: "Done",
										currentThumbnail : '',
										uploading : false				
									});
								} 							
	
								finishedSuccess = true;
							}
						}
	
					} else {
						_handleLoopError(`Error uploading ${file_name}. Please try again.`);
					}	
					
					if(abortUpload === true){ 
						setState({
							currentFile: 0,
							totalItems: 0,
							currentProgress: `Cancelled`,
							currentThumbnail : "",
							uploading : data.uploading
						});
					}	
				
				} else {
					_handleLoopError(`Unable to start upload of ${file_name}. (${uploadStartResult.data.error})`);
				}

				data.current_item++;
			}

			data.in_progress = false;
		};

		uploadFiles();
	}, [state.uploading]);

	const handleMyUploadsClick = function(evt){
		evt.preventDefault();
		evt.stopPropagation();
	}

	const handleCancelClick = function(evt){
		evt.preventDefault();
		evt.stopPropagation();

		abortUpload = true;
		setState({
			currentFile: 0,
			totalItems: 0,
			currentProgress: `Cancelling...`,
			currentThumbnail : ""				
		});
	}

	return (
		<UploadDrawerContentWrapper>
			<div className='uploadDropzone' 
				onDrop={dropHandler} 
				onDragOver={dragOverHandler} 
				onDragEnter={dragEnterHandler} 
				onDragLeave={dragLeaveHandler}>
					<Icon path={mdiFilePlusOutline}
						title="Drop files here to upload" 
						size={3}
						color={theme.colors.gray400}/>
					<p>Drag and drop files here</p>
					<Button  
						color = "blue"
						variant = "outlined"
						label = "Browse"
						mIcon = {FindInPageOutlinedIcon}
						onClick = {handleBrowseButtonClick}
						tooltip = "Browse for files on your computer"
					/>
			</div>
			{
				data.uploading &&
				<UploadProgressIndicator 
					currentItem={state.currentFile}
					totalItems={state.totalItems}
					currentProgress={state.currentProgress}
					thumbnail={state.currentThumbnail}
					onCancelClick={handleCancelClick}
					myUploadsHandler={handleMyUploadsClick}
					/>
			}
		</UploadDrawerContentWrapper>
	);
}