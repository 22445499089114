import React, { useState } from "react";
import loadable from 'react-loadable';

const _componentImports = {
    accessGroups : () => { return import('../../dataViews/DataViewAccessGroups'); },
    adminUsers : () => { return import('../../dataViews/DataViewAdminUsers'); },
	allAssets : () => { return import("../../dataViews/DataViewAssets"); },
    assetTags : () => { return import('../../dataViews/DataViewAssetTags'); },
	collectionsReview : () => { return import('../../dataViews/DataViewCollectionsReview'); },
    downloadRequests : () => { return import('../../dataviews/DataViewUserDownloadRequests'); },
    files : () => { return import('../../dataViews/DataViewFiles'); },
    myUploads : () => { return import('../../dataViews/DataViewMyUploads'); },
    newUserNotifications : () => { return import('../../dataViews/DataViewNewUserNotifications'); },
    users : () => { return import('../../dataViews/DataViewUsers'); },
    userUploads : () => { return import('../../dataViews/DataViewUserUploads'); },
}

const _loading = () => <p>loading...</p>;

export default function DamComponentLoader({ component }){
    if( _componentImports[component] === undefined ){ throw new Error(`DamComponentError undefined component : ${component}`); }
	return loadable( { loader: _componentImports[component], loading : _loading } );
}