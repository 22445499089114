import React, { useState, createContext, useEffect, useRef } from 'react';
import axios from 'axios';
const _ = require('lodash');

import { handle_dam_error } from './utils/libs/errorLib';

import {
	buildFilterObject,
	logoutWhereSessionHasExpired
} from './utils/libs/graphLib';

const objectAssignDeep = require(`object-assign-deep`);
const { ensure_object_path_exists } = require('./utils/libs/miscLib');

const SystemContext = createContext();
let contextData, setContextData;
let pollingReference;
const pollingRate = 1000 * 60 * 3;

export const SystemContextProvider = ({ children }) => {
	const mountedInitial = useRef(null);

	const [userData, setUserData] = useState({ data : {} });

	const [state, setState] = useState({
		removeItems: [],
		data: [],
		count: 0,
		limit: 25,
		skip: 0,
		loading: false,
		configLoadComplete : false
	});

	let getCollectionsForUser;

	const updateContext = (ctx) => {
		setContextData((prevData) => {
			return {...prevData, ...ctx};
		});
	};

	[contextData, setContextData] = useState({
		loading: true,
		searchTerm: "",
		useSavedViewSearchTerm: true,
		resetAssetsPage: false,
		updateContext,
		legacy : {
			user : null,
			downloads :  null,
			download_queue : []
		},
		currentPage : {
			name: "assets"
		},
		downloads_fetched: false,
		alerts : {
			all : {
				downloads : [],
				download_requests : []
			},
			new : {
				downloads : [],
				download_requests : []
			},
			original : {
				download_requests : []
			}
		},
		drawers : {
			notificationAddEdit : {
				open : false,
				updated : false
			},
			contactUs : {
				open : false
			},
			upload: {
				open : false
			},
			userProfile: {
				open : false
			}
		},
		platform : {
			admin_users : [],
			user_roles : []
		},
		featurePreview : {
		},
		getCollectionsForUser: (user) => { getCollectionsForUser(user) },
		initialDataPopulated : false,
		collections : null,
		asset_categories : null,
		access_groups : null,
		asset_types : null,
		system : {
			pause_polling : false
		}
	});

	useEffect(() => {
		mountedInitial.current = true;

		getCollectionsForUser = async (user_id) => {
			const { CollectionsPrefix } = require("@simpleview/sv-dam-client");
			const { GraphServer } = require("@simpleview/sv-graphql-client");
			const dam = new GraphServer({ graphUrl : "/ajax/graph.php", prefixes : [CollectionsPrefix] });

			let clientReturn = null;

			let domain = "";
			if (document.location.hostname !== undefined) {
				domain = document.location.hostname;
			}

			let collections = [];
			try{
				clientReturn = await dam.collections.fetch({
					fields : `
						success
						message
						docs {
							id
							title
						}
					`,
					filter : buildFilterObject( { mosaicFilter : {
						user_id: { value : parseInt(user_id) },
						active: { value : true }
					} } ),
					options : {
						limit : 9999,
						sort : { field_name : "title", direction: "asc" }
					}
				});
			}catch(err){
				if(logoutWhereSessionHasExpired(err)) return;
				handle_dam_error({
					public : "Request failed",
					debug : "SystemContext request failed",
					domain,
					data : {
						err
					}
				});
			}

			collections = ( _.has(clientReturn, 'docs') ) ? clientReturn.docs : [];
			UpdateSystemContext({
				...contextData,
				collections
			});
		}

		async function getConfigData() {
			setState({...state, loading:true});

			if(_.get(publicUser, 'custom_options', false) !== false) {
				const custom_options = _.get(publicUser, 'custom_options', false);
				setContextData({
					...contextData,
					legacy : { user : { custom_options } }
				});

			} else {
				//get user info
				const user = await axios.post("/ajax/ajax_logged_in.php");

				//get feature preview details
				const featurePreview = {
				};

				UpdateSystemContext({
					...contextData,
					legacy : {
						user : user.data,
						downloads: user.data.downloads
					},
					downloads_fetched : true,
					alerts : {
						new : {
							downloads : []
						},
						all : {
							downloads : ( user.data.downloads.items !== undefined && user.data.downloads.items.length > 0 ) ? user.data.downloads.items.map( (d) => {  return d.video_id; } ) : [],
						}
					},
					drawers : {
						notificationAddEdit : {
							open : false,
							updated : false
						},
						contactUs : {
							open : false
						},
						upload : {
							open : false
						}
					},
					featurePreview
				});

				if( user.data.user_type.match(/admin|master/i) ){
					clearInterval(pollingReference);

					const initialPollData = await axios.post('/ajax/admin_polling.php');

					if (_.get(initialPollData, 'data') !== undefined && _.get(initialPollData.data, 'sessionExpired') !== undefined && initialPollData.data.sessionExpired === true) {
						systemLogOut();
					}

					const download_requests = initialPollData.data.data.bbs.user_download_requests.docs.map( (d) => {  return d.id; } );

					UpdateSystemContext({
						...contextData,
						alerts : {
							all : {
								download_requests
							},
							original : {
								download_requests
							}
						}
					});

					pollingReference = setInterval(async function () {
						let openDrawers = false;
						if(contextData.drawers !== undefined){
							for (const [key, value] of Object.entries(contextData.drawers)) {
								if( value.open === true ){ openDrawers = true; }
							}
						}

						if( openDrawers === false && contextData.system.pause_polling === false ){//temporary check until we can move the drawers into their own context
							const pollData = await axios.post('/ajax/admin_polling.php');

							if (_.get(pollData, 'data') !== undefined && _.get(pollData.data, 'sessionExpired') !== undefined && pollData.data.sessionExpired === true) {
								systemLogOut();
							}

							const pollDownloadRequests = pollData.data.data.bbs.user_download_requests.docs.map( (d) => {  return d.id; } );

							const newDownloadRequests = pollDownloadRequests.filter( (d) => { return !contextData.alerts.original.download_requests.includes(d) && !contextData.alerts.all.download_requests.includes(d); } );

							UpdateSystemContext({
								alerts : {
									new : {
										download_requests : [...contextData.alerts.new.download_requests, ...newDownloadRequests]
									},
									all : {
										download_requests : pollDownloadRequests
									}
								}
							});

						}

					}, pollingRate);
				}
			}
		};

		async function getInitialData(){
			//need to populate contextData to pass in to fetchInitialData
			await getConfigData();

			const platformStructure = {
				assetOwner : "",
				domain : ""
			}
			if (contextData.legacy.user.asset_owner !== undefined) {
				platformStructure.assetOwner = contextData.legacy.user.asset_owner;
			}
			if (document.location.hostname !== undefined) {
				platformStructure.domain = document.location.hostname;
			}

			//filter categories to only full and accessible to this user if they are not an admin/master and are not assigned to "all assets"
			let restrictToUser = { user_id : -1 };
			const user = _.has(contextData, "legacy.user") ? contextData.legacy.user : null;

			if(user !== null && _.has(user, "user_type") && !user.user_type.match(/admin|master/i) && !(_.has(user, 'user_access_groups') && user.user_access_groups !== null && handleArrayCheck(user.user_access_groups).length < 1)){
				restrictToUser.user_id = parseInt( user.id, 10 );
			}

			//check for admin access group
			function handleArrayCheck(arr) {
				let isAdmin = arr.filter((item) => {
					return item.is_admin_group === true
				});
				return isAdmin;
			}

			const { AccessGroupsPrefix, AssetCategoriesPrefix, AssetTypesPrefix, UsersPrefix, CollectionsPrefix } = require("@simpleview/sv-dam-client");
			const { GraphServer } = require("@simpleview/sv-graphql-client");
			const dam = new GraphServer({ graphUrl : "/ajax/graph.php", prefixes : [AccessGroupsPrefix, AssetCategoriesPrefix, AssetTypesPrefix, UsersPrefix, CollectionsPrefix] });

			let clientReturn;
			let access_groups;
			let asset_categories;
			let asset_types;
			let collections;

			try {
				clientReturn = await dam.users.fetchInitialData({ context : contextData, restrictToUser });

				if( _.has(clientReturn, "bbs")){
					access_groups = _.has(clientReturn, "bbs.access_groups.docs") ? clientReturn.bbs.access_groups.docs : null;
					asset_categories = _.has(clientReturn, "bbs.asset_categories.docs") ? clientReturn.bbs.asset_categories.docs : null;
					asset_types = _.has(clientReturn, "bbs.asset_types.docs") ? clientReturn.bbs.asset_types.docs : null;
					collections = _.has(clientReturn, "bbs.collections.docs") ? clientReturn.bbs.collections.docs : null;
				}

			} catch (err) {
				if(logoutWhereSessionHasExpired(err)) return;

				handle_dam_error({
					public : "Request failed [5]",
					debug : "System Context fetchInitialData request failed",
					assetOwner : platformStructure.assetOwner,
					domain : platformStructure.domain,
					data : {
						err
					}
				});
			}

			UpdateSystemContext({
				...contextData,
				access_groups,
				asset_categories,
				asset_types,
				collections,
				initialDataPopulated : true
			});
		}

		getInitialData();
		return () => mountedInitial.current = false;
	}, []);

	return (
		<SystemContext.Provider value={contextData}>
			{contextData.legacy.user !== null &&
				<>{children}</>
			}
		</SystemContext.Provider>
	);
};

export const UpdateSystemContext = (newContextData) => {
	//see warning about circular references and native objects https://www.npmjs.com/package/object-assign-deep#caution-danger-of-death

	if( _.get(contextData, 'alerts.all') !== undefined && contextData.downloads_fetched === true ){
		//handle differences for new key
		if( _.get(contextData.alerts.all, 'downloads') !== undefined  && _.get(newContextData, 'alerts.all.downloads') !== undefined ){
			const queueDifference = newContextData.alerts.all.downloads.filter( (d) => { return !contextData.alerts.all.downloads.includes(d); } );

			newContextData = ensure_object_path_exists({ object : newContextData, path : 'alerts.new.downloads' });
			newContextData.alerts.new.downloads = [...contextData.alerts.new.downloads, ...queueDifference];
		}
	}
	const newContext = objectAssignDeep({...contextData}, newContextData);

	setContextData(newContext);
}

export default SystemContext;