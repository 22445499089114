import React from 'react';
import { Button } from "@simpleview/sv-mosaic";
import styled from 'styled-components';

import HelpIcon from '@material-ui/icons/Help';
import SystemContext, { UpdateSystemContext } from "../../SystemContext";

export default function HeaderHelpMenu() {

	const context = React.useContext(SystemContext);

	const HelpDiv = styled.div`
		display: flex;
		max-height: 100%;

		& button { color :#fff !important; }
		& button:hover { background: rgba(255, 255, 255, .3) !important; }
	`;

	const helpMenuItems = [];

	if (!(Cookies.get('nav_preview') !== undefined && Cookies.get('nav_preview') === "1")) {

		helpMenuItems.push(
			{
				label : "Client Portal",
				onClick : function() { window.open(`https://portal.simpleviewinc.com/`, '_blank'); }
		   }
		);
		
		helpMenuItems.push(
			{
				label : "Search Overview",
				onClick : function() { window.open(`https://help.simpleviewinc.com/hc/en-us/articles/16273993728403-Admin-Search-Functionality#admin-search-functionality-0-0`, '_blank'); }
		   }
		);

		helpMenuItems.push(
			{
				label : "Knowledgebase",
				onClick : function() { window.open(`https://help.simpleviewinc.com/hc/en-us/sections/1500001940161-DAM`, '_blank'); }
		   }
		);

	}

	helpMenuItems.push(
		{
			label : translate("Contact Us"),
			onClick : function() { 
				const newContext = {
					...context
				};
				newContext.drawers.contactUs.open = true;
				UpdateSystemContext(newContext);
			}
	  	}
	);

	return (
		<HelpDiv>
			<Button  
				color = "blue"
				variant = "icon"
				mIcon = {HelpIcon}
				menuItems={helpMenuItems}
				tooltip="Get Help"
			/>
		</HelpDiv>
	);
	
}