import React, { useState } from "react";
import styled from "styled-components";
const _ = require("lodash");
import { Button, theme } from "@simpleview/sv-mosaic";
import SystemContext, { UpdateSystemContext } from "../../SystemContext";
import DisplayBridgeHelper from "../../utils/helpers/DisplayBridgeHelper";
import Cookies from "js-cookie";

export default function UserOptions(props) {
	const context = React.useContext(SystemContext);
	const languages = _.get(context, "legacy.user.custom_options.languages", false);

	const userInitial = ( props.user.first_name.trim().length > 0 ) ? props.user.first_name.trim().substring(0, 1).toUpperCase() : "U";

	const OptionsDiv = styled.nav`
		display: flex;
		max-height: 100%;
		margin-left: 10px;

		& button { 
			height: 40px; 
			width: 40px; 
			border-radius: 20px;
			background: #fff !important;
			font-family: ${theme.fontFamily}; 
			font-size: 1.7rem;
			color: ${theme.colors.gray600}; 
		}
	`;

	const OptionsMenuItems = [];

	if (languages !== false && languages.length > 1) {
		OptionsMenuItems.push(
			{
				label : translate("Select Language"),
				onClick : function() {
					const newContext = {
						...context
					};
					newContext.drawers.userProfile.open = true;
					UpdateSystemContext(newContext);
				}
			},
		)
	}
	
	OptionsMenuItems.push(
		{
			label : translate("Terms & Conditions"),
			onClick : function() {
				const newContext = {};

				(new DisplayBridgeHelper).closeSubviews();
		
				document.getElementById('termsConditions').style.display = 'initial';
				document.getElementById('dataViewContainer').style.width = 'initial';

				newContext.currentPage = {
					name: 'user.termsConditions',
					type: 'legacy',
					id: 'termsConditions'
				};
		
				UpdateSystemContext(newContext);
			}
		},
		{
			label : translate("Change Password"),
			onClick : function() {
				const newContext = {};
				
				(new DisplayBridgeHelper).closeSubviews();

				document.getElementById('adminReportsContent').src = '/administration5/edit_account.php';
				document.getElementById('adminReports').style.display = 'initial';
				document.getElementById('dataViewContainer').style.width = 'initial';

				newContext.currentPage = {
					name: 'user.changePassword',
					type: 'legacy',
					id: 'adminReports'
				};
		
				UpdateSystemContext(newContext);
			}
		},
	)

	//Log out added here to stay at bottom of dropdown
	OptionsMenuItems.push(
		{
			label : translate("Log Out"),
			onClick : function() { 
				systemLogOut(); //invoke hoisted log out
			}
		},
	)
	
	return (		
		<OptionsDiv>				
			<Button  
				color = "blue"
				variant = "text"
				label = {userInitial}
				menuItems = {OptionsMenuItems}
				tooltip = { translate("User Options") }
			/>
		</OptionsDiv>		
	);
	
}