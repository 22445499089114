export function ensure_object_path_exists( { object, path } ){
	if(_.has(object, path) === false){
		const return_object = object;

		const path_components = path.split('.');
		let key_path = return_object;
		for(let pathCtr = 0; pathCtr < path_components.length; pathCtr++){
			if(key_path[path_components[pathCtr]] === undefined){
				key_path[path_components[pathCtr]] = {};
			}
			key_path = key_path[path_components[pathCtr]];
		}

		return return_object;
	}else{
		return object;
	}
}