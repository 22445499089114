import React from "react";
import ReactDOM from "react-dom";

import styled from 'styled-components';
import SearchBar from "./childComponents/DamHeader/SearchBar";
import Login from './childComponents/DamHeader/Login';
import HeaderHelpMenu from './childComponents/DamHeader/HeaderHelpMenu';
import HeaderAlertMenu from  './childComponents/DamHeader/HeaderAlertMenu';
import SimpleviewLogo from './childComponents/DamHeader/SimpleviewLogo';
import PlatformSwitcher from './childComponents/DamHeader/PlatformSwitcher';
import HeaderMobileMenuTrigger from "./childComponents/DamHeader/HeaderMobileMenuTrigger";
import UserOptions from "./childComponents/DamHeader/UserOptions";

export default function DamHeader(props) {

	const Header = styled.header`
		align-items: center;
		color: white;
		background-color: black;
		display: flex;
		height: 64px;
		justify-content: space-between;
		padding: 0.7em 2.85em 0.7em 2.15em;
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 1201;
	`;

	const PlatformSwitchContainer = styled.div`
		left: 210px;
		position: absolute;

		& div {
			outline: none;
		}
	`;
	
	const currentUser = JSON.parse(props.currentUser);

	return ReactDOM.createPortal(
		<Header>
			<HeaderMobileMenuTrigger/>
			<SimpleviewLogo/>
			{ 
				currentUser.currentAssetOwner != undefined
				&& Object.getOwnPropertyNames(currentUser.assetOwners).length > 1 
				&& (
					<PlatformSwitchContainer>
						<PlatformSwitcher currentAssetOwner={currentUser.currentAssetOwner} assetOwners={currentUser.assetOwners} />
					</PlatformSwitchContainer>
				) 
			}
			<SearchBar/>
			<HeaderHelpMenu/>
			<HeaderAlertMenu />
			{
				(
					currentUser.user === undefined
					|| currentUser.user.logged_in === undefined
					|| currentUser.user.logged_in == false
				)
				&& ( <Login/> )
			}
			{
				currentUser.user !== undefined
				&& currentUser.user.logged_in !== undefined
				&& currentUser.user.logged_in == true
				&& ( 
					<UserOptions user={currentUser.user} /> 
				)
			}
		</Header>
	, document.getElementById("siteHeaderContainer"));
}