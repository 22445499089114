import React from "react";
import DamDrawer from "../DamDrawer";
import DamDrawerUserProfileContent from "./DamDrawerUserProfileContent";

export default React.memo( function UserProfileDrawer(props) {
	return (
		<DamDrawer
			open={true}
			title={translate("Select Language")}
			onClose={props.onClose}
		>
			<DamDrawerUserProfileContent />
		</DamDrawer>
	);
})