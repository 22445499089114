import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from "@simpleview/sv-mosaic";

export default function HeaderMobileMenuTrigger() {

	const [state, setState] = useState({menuVisible:false}); 

	const HeaderMobileMenuTriggerDiv = styled.div`
		display:none;
		${({ visible }) => visible && `
			display: block;
		`}

	`;

	useEffect(() => {
		if(document.getElementById('damNavMenuStatus') !== null) {
		 	document.getElementById('damNavMenuStatus').addEventListener('change', (val) => {
		 		setState({menuVisible:(val.target.value === 'hidden' || val.target.value === 'mobile')});
		 	});
		}
	}, []);

	function clickHandler() {
		document.getElementById('triggerMenu').click();
	}

	return (
		<HeaderMobileMenuTriggerDiv visible={state.menuVisible}>
			{ document.getElementById('triggerMenu') !== null && 
				<Button
					color="blue"
					variant="text" 
					mIcon={MenuIcon} 
					onClick={clickHandler} 
					tooltip="Open Menu"
					mIconColor="white"
				/>
	}
		</HeaderMobileMenuTriggerDiv>
	);
	
}