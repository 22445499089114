import React from "react";
import styled from 'styled-components';

export default function SimpleviewLogo() {

	const StyledDiv = styled.div`
		background-color: black;
		color: white;
		max-width: 100%;
	
		& > img {
			max-width: 150px;
		}
	`;
 
	return (
		<StyledDiv>
			<img src="/images/logo-simpleview-reverse.png" alt="Simpleview" />
		</StyledDiv>
	);
	
}