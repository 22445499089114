const _ = require('lodash');

//builds a filter for graph/mosaic
export function buildFilterObject( { mosaicFilter, filterMap = null, dataConversionMap = null } ){
	if( mosaicFilter === undefined ){ throw new Error('missing filter object'); }

	const returnFilter = {};

	const _determineKeyName = (key) => {
		return ( filterMap !== null && filterMap[key] !== undefined ) ? filterMap[key] : key;
	}

	const _determineValue = (key, value) => {
		if( dataConversionMap === null || dataConversionMap[key] === undefined ){ return value; }

		switch( dataConversionMap[key] ){
			case "int" :
				return Array.isArray(value) ? value.map( val => { return parseInt(val) } ) : parseInt(value);
			default:
				return value;
		}
	}

	if(
		mosaicFilter.constructor === Object
		&& Object.keys(mosaicFilter).length > 0
	){
		Object.keys(mosaicFilter).forEach( key => {
			let value = mosaicFilter[key];
			if ( typeof value === 'object' && 'value' in value === true ){ value = value.value; }

			if( value === undefined ){ return; }//handle "cleared" filters
			if( Array.isArray(value) === true && value.length < 1 ) { return; } //handle empty arrays

			if( value.comparison !== undefined ){//most filters should have a comparison
				switch ( value.comparison ){
					case "equals" :
						returnFilter[ _determineKeyName(key) ] = _determineValue(key, value);
						break;

					default:
						returnFilter[ _determineKeyName(key) ] = _determineValue(key, value);
						break;
				}
			}else{
				returnFilter[ _determineKeyName(key) ] = _determineValue(key, value);
			}
		});
	}

	return returnFilter;
}

//builds a sort for graph/mosaic
export function buildSortObject( { mosaicSort, sortMap = null, defaultSort = {} } ){
	if( mosaicSort === undefined ){ throw new Error('missing sort object'); }

	//check for empty object
	if( mosaicSort.name !== undefined && mosaicSort.dir !== undefined ){
		if(sortMap === null || sortMap[ mosaicSort.name ] === undefined ){
			return [
				{
					field_name : mosaicSort.name,
					direction : mosaicSort.dir
				}
			];
		}else{
			return [
				{
					field_name : sortMap[ mosaicSort.name ],
					direction : mosaicSort.dir
				}
			];
		}
	}else{//handle empty object
		if(
			Array.isArray(defaultSort) === true
			&& defaultSort.length > 0
		){
				return [ ...defaultSort ];
		}else{
			return null
		}
	}
}

// Check error object for graphQLErrors to see if the session has expired. If so, log the user out.
export function logoutWhereSessionHasExpired(err) {
	if(_.has(err, "graphQLErrors") && err.graphQLErrors.find(e => e.message === "sessionExpired")){
		console.log("Session expired - logging user out.");
		systemLogOut();
		return true;
	}
	return false;
}