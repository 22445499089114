import React, { useContext, useRef } from "react";
import styled from "styled-components";
import SystemContext from "../../SystemContext";
import _ from "lodash";

export default function SearchBar() {

	const context = useContext(SystemContext);
	const userInput = useRef("");

	const SearchDiv = styled.div`
		display: flex;
		max-height: 100%;
		margin-left: auto;
		margin-right: 10px;
		& .inputContainer {
			position: relative;
			align-items: center;
			border-radius: 0.3em;
			display: flex;
			justify-content: center;
			max-height: 100%;
			overflow: hidden;
			width: 25em;
		}
		& .inputContainer button{
			background-color: inherit !important;
			border: none !important;
			border-radius: 50% !important;
			color: #848791 !important;
			display: flex;
			flex-direction: column;
			height: 2.3em!important;
			justify-content: center;
			outline: none;
			padding: 0.3em 0.3em 0.3em 0.5em !important;
			position: absolute;
			right: 10px !important
			top: inherit !important;
			width: 2.3em !important;
			&:hover &:active,
			&:focus &:active {
				background-color: #ddd !important;
			}
			cursor: ${context.loading === true ? 'progress' : 'pointer'};

		}
		& .inputContainer input{
			background-color: rgba(255,255,255,0.2);
			border: none;
			border-radius: 0.3em;
			color: #aaa;
			font-size: 1.2em;
			outline: none;
			padding: 3em 50px 3em 1em;
			width: 25em;
			&:focus {
				background-color: #eee;
				color: #333;
				width: 25em;
				&::placeholder {
					color: #333;
				}
			}
			&::placeholder &:active {
				color: #ccc;
			}
		}
		& .inputContainer .icon{
			font-size: 2em;
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
		}
	`;

	const handleFormSubmission = (e) => {
		e.preventDefault();

		const userEnteredSearchTerm = userInput.current.value.trim();

		if (userEnteredSearchTerm.length !== 0 && userEnteredSearchTerm.length < 3) {
			alert("Search requires at least 3 characters.");
			return;
		}

		const newContextParams = {
			searchTerm: userEnteredSearchTerm,
			useSavedViewSearchTerm: false,
			resetAssetsPage: new Date()
		};

		if (!(_.get(context, 'currentPage.name', false) === "assets") && document.getElementById('all-assets-nav-item-bridge-nav-item') !== undefined) {
			document.getElementById('all-assets-nav-item-bridge-nav-item').click();
			resetViewContainersForReact();

			newContextParams.currentPage = {
				name: 'assets',
				type: 'transitional',
				id: false
			}

		}

		context.updateContext(newContextParams);
	}

	return (
		<SearchDiv>
			<form id="searchFormReact" onSubmit={handleFormSubmission}>
				<div className="inputContainer">
					<input ref={userInput} disabled={context.loading} name="searchText" type="text" autoComplete="off" placeholder={translate("Search")} defaultValue={context.searchTerm} />
					<button id="btnSearchReact" disabled={context.loading} type="submit" ><div className="icon">&#9906;</div></button>
				</div>
			</form>
		</SearchDiv>
	);

}