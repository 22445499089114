import React, { useEffect, useRef } from "react";
import DrawerContactUs from "./drawerContent/DrawerContactUs";
import DrawerNotificationAddEdit from "./drawerContent/DrawerNotificationAddEdit";
import DrawerUpload from "./drawerContent/DrawerUpload";
import UserProfileDrawer from "./drawerContent/UserProfileDrawer";
import SystemContext, { UpdateSystemContext } from './SystemContext';
const _ = require('lodash');

export default React.memo( function DamDrawers() {
	const context = React.useContext(SystemContext);

	const handleContactUsDrawerClose = function(){
		const newContext = {
			...context,
			drawers : { ...context.drawers }
		};
		newContext.drawers.contactUs.open = false;

		UpdateSystemContext(newContext);
	};

	const handleNotificationAddEditDrawerClose = function(){
		const newContext = {
			...context
		};
		newContext.drawers.notificationAddEdit.open = false;

		UpdateSystemContext(newContext);
	};

	const handleUploadDrawerClose = function(){
		const newContext = {
			...context
		};
		newContext.drawers.upload.open = false;

		UpdateSystemContext(newContext);
	};

	const handleUserProfileDrawerClose = function(){
		const newContext = {
			...context
		};
		newContext.drawers.userProfile.open = false;

		UpdateSystemContext(newContext);
	};

	const mountedInitial = useRef(null);


	useEffect(() => {

		const handleContactUsClick = (evt) => {
			evt.preventDefault();
			evt.stopPropagation();

			const newContext = {
				...context
			};
			newContext.drawers.contactUs.open = true;

			UpdateSystemContext(newContext);
		};

		if(!mountedInitial.current){
			if(document.getElementById('contact-us-nav-item') !== null) {
				document.getElementById('contact-us-nav-item').addEventListener('click', handleContactUsClick);
			}
			if(document.getElementById('triggerReactContactUs') !== null) {
				document.getElementById('triggerReactContactUs').addEventListener('click', handleContactUsClick);
			}
		}

		mountedInitial.current = true;
	});

	return (	
		<div>
			{ _.get(context, 'drawers.contactUs.open') !== undefined && context.drawers.contactUs.open && 
				<DrawerContactUs
					onClose={handleContactUsDrawerClose}
				/>
			}
			{ _.get(context, 'drawers.notificationAddEdit.open') !== undefined && context.drawers.notificationAddEdit.open && 
				<DrawerNotificationAddEdit
					showNotificationAddEditDrawer={context.drawers.notificationAddEdit.open}
					onClose={handleNotificationAddEditDrawerClose}
				/>
			}
			{ _.get(context, 'drawers.upload.open') !== undefined && context.drawers.upload.open &&
				<DrawerUpload
					onClose={handleUploadDrawerClose}
				/>
			}
			{ _.get(context, "drawers.userProfile.open") !== undefined && context.drawers.userProfile.open &&
				<UserProfileDrawer
					onClose={handleUserProfileDrawerClose}
				/>
			}
		</div>
	);
})