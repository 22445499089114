import React, { useContext } from "react";
import styled from "styled-components";
import DamForm from "@simpleview/sv-dam-form";
const _ = require("lodash");

import SystemContext, { UpdateSystemContext } from "../SystemContext";

export default React.memo(function UserProfileDrawerContent(props){
	const context = useContext(SystemContext);
	const languages = _.get(context, "legacy.user.custom_options.languages", false);
	const currentLanguage = document.getElementById("dataViewContainer").getAttribute('data-current-language');

	const languageMap = {
		"en" : "English",
		"es" : "Spanish",
		"jp" : "日本語",
		"fr" : "French"
	}

	const UserProfileDrawerContentWrapper = styled.div`
		display: block;
		height: calc(100vh - 84px);
		position: relative;
	`;

	const handleFormSubmission = function(e) {
		const lang = e.languageSelect;

		if(currentLanguage === lang) {
			return;
		}

		const newContext = {
			...context
		};

		newContext.drawers.userProfile.open = false;

		UpdateSystemContext(newContext);

		bs.translate.change(lang);
	}

	const languageOptions = languages.map((language) => {
		const languageLabel = languageMap[language];
		return { value : language, label : languageLabel };
	});

	const formConfig = {
        onSubmit : handleFormSubmission,
		defaultValues : {
			languageSelect : currentLanguage
		},
        sections : [
          {
            fields : [
				{
					name : "languageSelect",
					type : "filterSingleSelect",
					label : "Please select your language",
					options : languageOptions
				}
            ]
		  }
		]
    };

	return (
		<UserProfileDrawerContentWrapper>
			<DamForm
				config={formConfig}
			/>
		</UserProfileDrawerContentWrapper>
	);
})